import React, { useContext, useEffect, useMemo, useState } from "react";
import Cookies from "js-cookie"; // Import cookies library
import { CartContext } from "../Contexts/CartContext";
import { json, useNavigate } from "react-router-dom";
import { Elements, ExpressCheckoutElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { MdDiscount } from "react-icons/md";
import getUserLocale from "get-user-locale";

const Cart = () => {
    const { cartItems, increaseQuantity, decreaseQuantity, totalValue } = useContext(CartContext);
    const navigate = useNavigate();
    const lang = getUserLocale();

    // Assume the required product is the iPhone.
    // Adjust the handle ("iphone") as needed.
    const hasIphone = useMemo(() => cartItems.some(item => item.handle === "🍏phone"), [cartItems]);

    // Memoize the free‑eligible handles.
    const freeEligibleHandles = useMemo(
        () => ["pro-2", "rpods-gen4", "rpods-gen-3", "rpods-pro-1"],
        []
    );

    // Transform cart items.
    // For free‑eligible products, if an iPhone is present,
    // split into two entries: one free unit and the remaining paid units.
    const displayCartItems = useMemo(() => {
        let items = [];
        cartItems.forEach(item => {
            if (freeEligibleHandles.includes(item.handle) && hasIphone) {
                if (item.quantity > 0) {
                    // Add one free unit.
                    items.push({
                        ...item,
                        quantity: 1,
                        price: 0,
                        isFree: true,
                    });
                    // Add additional paid units if quantity > 1.
                    if (item.quantity > 1) {
                        items.push({
                            ...item,
                            quantity: item.quantity - 1,
                            isFree: false,
                        });
                    }
                }
            } else {
                // Non-eligible products or eligible products without an iPhone
                // are added without modification.
                items.push(item);
            }
        });
        return items;
    }, [cartItems, freeEligibleHandles, hasIphone]);

    // Calculate total price.
    // For free‑eligible items, if an iPhone is present, only charge for extra (paid) units.
    const computedTotalPrice = useMemo(() => {
        return cartItems.reduce((total, item) => {
            if (freeEligibleHandles.includes(item.handle) && hasIphone) {
                return total + item.price * Math.max(item.quantity - 1, 0);
            }
            return total + item.price * item.quantity;
        }, 0);
    }, [cartItems, freeEligibleHandles, hasIphone]);

    const totalPrice = computedTotalPrice;
    const [appliedCoupons, setAppliedCoupons] = useState([]);
    const appliedDiscount = useMemo(
        () => appliedCoupons.reduce((total, coupon) => total + coupon.discount, 0),
        [appliedCoupons]
    );
    const discountedPrice = Math.max(totalPrice - totalPrice * (appliedDiscount / 100), 0);

    const appearance = {};
    const [clientSecret, setClientSecret] = useState();
    const stripePromise = loadStripe(
        "pk_live_51Ozc6fFqQPKh8TUqUUqKnd5DnZdakxxj67nxBO8ImV1V5fzZWARjGMr0xOrnXYIJn1inum5JGRasaahjtat8j9gJ00z7sBXc7z"
    );

    const [payButton, setPayButton] = useState(null);
    const [selectedUrl, setUrl] = useState(null);
    const [couponCode, setCouponCode] = useState(""); // For coupon input
    const [error, setError] = useState(""); // For coupon errors

    // Helper functions for formatting prices.
    const formatUnitPrice = (price) => {
        return price === 0
            ? "Zdarma"
            : lang.includes("cs")
                ? price + " Kč"
                : (price / 25).toFixed(2) + " €";
    };

    const formatPrice = (price, quantity) => {
        const total = price * quantity;
        return price === 0
            ? "Zdarma"
            : lang.includes("cs")
                ? total.toLocaleString() + " Kč"
                : (total / 25).toFixed(2) + " €";
    };

    // Load applied coupons from cookies on mount.
    useEffect(() => {
        const savedCoupons = Cookies.get("applied-coupons");
        if (savedCoupons) {
            const parsedCoupons = JSON.parse(savedCoupons);
            if (Array.isArray(parsedCoupons) && parsedCoupons.length > 0) {
                setAppliedCoupons(parsedCoupons);
            }
        }
    }, []);

    useEffect(() => {
        // Scroll to the top and set document title.
        window.scrollTo(0, 0);
        document.title = `Cart - ResellerHeaven`;
        return () => {
            document.title = "ResellerHeaven";
        };
    }, []);

    // Save applied coupons to cookies whenever they change.
    useEffect(() => {
        if (appliedCoupons.length > 0) {
            Cookies.set("applied-coupons", JSON.stringify(appliedCoupons), { expires: 7 });
        } else {
            Cookies.remove("applied-coupons");
        }
    }, [appliedCoupons]);

    useEffect(() => {
        let finalPrice = lang.includes("cs") ? discountedPrice : discountedPrice;
        let currency = lang.includes("cs") ? "czk" : "eur";
        console.log(lang + " " + currency + " " + finalPrice);
    }, [discountedPrice, lang]);

    // Build items for API calls based on displayCartItems.
    const [items, setItems] = useState([]);
    useEffect(() => {
        if (displayCartItems.length > 0) {
            const updatedItems = displayCartItems.map((item) => ({
                productTitle: item.title,
                quantity: item.quantity,
                variantTitle: item.selectedOptions
                    ? Object.values(item.selectedOptions).map(option => ` | ${option} `).join('') + '|'
                    : '',
                imageUrl: item.img,
            }));
            setItems(updatedItems);
        }
    }, [displayCartItems]);

    useEffect(() => {
        let finalPrice = lang.includes("cs") ? discountedPrice : discountedPrice / 25;
        let currency = lang.includes("cs") ? "czk" : "eur";
        fetch('https://api.whustle.com/product-pay/' + finalPrice + '/' + currency + '/ResellerHeaven', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ cartItems: items })
        })
            .then(response => response.json())
            .then(data => {
                setUrl(data.url);
            });
    }, [discountedPrice, items, lang]);

    useEffect(() => {
        const updatePaymentIntent = async () => {
            if (totalValue === 0) return;
            let finalPrice = lang.includes("cs") ? discountedPrice : discountedPrice / 25;
            let currency = lang.includes("cs") ? "czk" : "eur";
            try {
                const response = await fetch("https://api.resellerheaven.eu/create-or-update-payment-intent2/" + currency, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ totalAmount: finalPrice }),
                });
                const data = await response.json();
                setClientSecret(data.clientSecret);
                setPayButton(
                    <div key={"" + totalValue}>
                        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                            <ExpressCheckoutElement onConfirm={() => console.log("Express Checkout Success")} />
                        </Elements>
                    </div>
                );
            } catch (error) {
                console.error("Error updating payment intent:", error);
            }
        };

        updatePaymentIntent();
    }, [discountedPrice, lang, totalValue]);

    const handleApplyCoupon = () => {
        // Define valid coupons.
        const validCoupons = {
            "ADAM2000": { discount: 20, code: "ADAM2000" },
            "KLUCIZPRAHY": { discount: 20, code: "KLUCIZPRAHY" },
            "DISCOUNT10": { discount: 10, code: "DISCOUNT10" }
        };

        if (validCoupons[couponCode]) {
            if (appliedCoupons.some(coupon => coupon.code === couponCode)) {
                setError("Tento kód již byl použit.");
                return;
            }
            if (appliedCoupons.some(coupon => coupon.code === "ADAM2000") && couponCode === "KLUCIZPRAHY") {
                setError("Tento kód nelze kombinovat.");
                return;
            }
            if (appliedCoupons.some(coupon => coupon.code === "KLUCIZPRAHY") && couponCode === "ADAM2000") {
                setError("Tento kód nelze kombinovat.");
                return;
            }
            setAppliedCoupons([...appliedCoupons, validCoupons[couponCode]]);
            setCouponCode("");
            setError("");
        } else {
            setError("Neplatný slevový kód");
        }
    };

    const handleRemoveCoupon = (code) => {
        setAppliedCoupons(appliedCoupons.filter(coupon => coupon.code !== code));
    };

    return (
        <div className="w-full">
            <div className="w-full md:w-3/5 mx-auto px-4 grid mt-44 mb-8 py-6 bg-transparent rounded-md shadow-md">
                <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">Tvůj Košík</h2>
                {displayCartItems.length > 0 ? (
                    <div>
                        <table className="w-full text-left">
                            <thead>
                            <tr>
                                <th className="py-2">Product</th>
                                <th className="py-2 text-center">Quantity</th>
                                <th className="py-2 text-right">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {displayCartItems.map((item, index) => (
                                <tr key={index} className="border-b border-t border-[#262626]">
                                    <td className="py-4">
                                        <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                            <button onClick={() => navigate("/products/" + item.handle)}>
                                                <img
                                                    src={item.img}
                                                    alt={item.title}
                                                    className="w-16 h-16 md:w-24 md:h-24 object-cover rounded"
                                                />
                                            </button>
                                            <div className="mt-2 md:mt-0">
                                                <h3 className="text-gray-800 dark:text-white font-semibold">
                                                    {item.title}
                                                </h3>
                                                {item.selectedOptions && (
                                                    <div className="text-sm text-gray-500">
                                                        {Object.entries(item.selectedOptions).map(([key, value]) => (
                                                            <p key={key}>
                                                                {key}: {value}
                                                            </p>
                                                        ))}
                                                    </div>
                                                )}
                                                <p className="text-sm text-gray-500">
                                                    Cena: {formatUnitPrice(item.price)}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="flex py-8 md:py-4 md:mt-5 mt-11 text-center items-center justify-center">
                                        <div className="border rounded-xl py-2 md:py-3 grid grid-cols-3 w-16 md:w-32 justify-items-center">
                                            <button onClick={() => decreaseQuantity(item)} className="text-white w-full">
                                                -
                                            </button>
                                            <span className="text-gray-800 dark:text-white">{item.quantity}</span>
                                            <button onClick={() => increaseQuantity(item)} className="text-white w-full">
                                                +
                                            </button>
                                        </div>
                                    </td>
                                    <td className="py-4 text-center px-1 md:text-right text-gray-800 dark:text-white w-32">
                                        {formatPrice(item.price, item.quantity)}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <div className="mt-4">
                            <div className="flex items-center space-x-2">
                                <input
                                    type="text"
                                    value={couponCode}
                                    onChange={(e) => setCouponCode(e.target.value)}
                                    placeholder="Zadejte slevový kód"
                                    className="px-4 py-3 rounded-full text-white bg-black border border-white w-2/3 md:w-1/3"
                                />
                                <button
                                    onClick={handleApplyCoupon}
                                    className="px-4 py-3 bg-white text-black hover:bg-gray-200 rounded-full"
                                >
                                    Použít
                                </button>
                            </div>
                            {error && <p className="text-red-500 mt-2">{error}</p>}
                            {appliedCoupons.length > 0 && (
                                <div className="md:flex md:space-x-2 grid grid-cols-2 mt-4">
                                    {appliedCoupons.map((coupon) => (
                                        <div
                                            key={coupon.code}
                                            className="flex items-center space-x-2 bg-gray-100 px-4 py-2 rounded-full w-fit mb-2"
                                        >
                                            <MdDiscount className="text-gray-700" />
                                            <span className="text-gray-700 text-sm">{coupon.code}</span>
                                            <button
                                                onClick={() => handleRemoveCoupon(coupon.code)}
                                                className="text-gray-500 hover:text-red-500 transition"
                                            >
                                                ×
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="mt-6">
                            <h3 className="text-xl font-semibold text-gray-800 dark:text-white">Částka Celkem</h3>
                            <div className="flex items-center space-x-4 mt-2">
                                <p className="text-lg font-bold text-gray-800 dark:text-white">
                                    {lang.includes("cs")
                                        ? discountedPrice.toLocaleString() + " Kč"
                                        : (discountedPrice / 25).toFixed(2) + " €"}
                                </p>
                                {appliedDiscount > 0 && (
                                    <p className="text-lg font-bold text-gray-500 line-through">
                                        {lang.includes("cs")
                                            ? totalPrice.toLocaleString() + " Kč"
                                            : (totalPrice / 25).toFixed(2) + " €"}
                                    </p>
                                )}
                            </div>
                            {appliedDiscount > 0 && <p className="text-green-500">Sleva: {appliedDiscount}%</p>}
                            <p className="text-sm text-gray-500">
                                Tax included and shipping calculated at checkout
                            </p>
                        </div>

                        <div className="mt-6 flex flex-col md:w-2/6 space-y-3 md:space-y-2 justify-between">
                            <button
                                onClick={() => navigate("/")}
                                className="px-5 py-3 bg-gray-200 dark:bg-white text-black rounded-full hover:bg-gray-300"
                            >
                                Pokračovat v Nákupu
                            </button>
                            <div>{clientSecret && <div className={"rounded-full shadow-2xl"}>{payButton}</div>}</div>
                            <button
                                className={"px-5 py-3 bg-green-400 text-white rounded-full hover:bg-green-500"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = selectedUrl;
                                }}
                            >
                                Zaplatit nyní
                            </button>
                        </div>
                    </div>
                ) : (
                    <p className="text-gray-500 text-center mt-6">Tvůj košík je prázdný</p>
                )}
            </div>
        </div>
    );
};

export default Cart;
